//import manager from '@/utils/manager'
import api from '@/utils/fhapi'

export function accountInfo(params) {
    return api.get('wx/v1/account', { params:params })
}

export function gameList(params) {
    return api.get('wx/v1/gold/game', { params:params })
}

export function serverList(params) {
    return api.get('wx/v1/fenghuo/server', { params:params })
}

export function roleList(params) {
    return api.get('wx/v1/fenghuo/role', { params:params })
}

export function productsList(params) {
    return api.get('wx/v1/gold/products', { params:params })
}

export function createOrder(params) {
    return api.post('wx/v1/jzac', params)
}

export function fenghuoCreateOrder(params) {
    return api.post('wx/v1/fenghuo/common/jzac', params)
}

export function pay (params) {
    return api.post('wx/v1/fenghuo/pay', params)
}

// 支付包支付或paypel支付
export function payMix (params) {
    return api.post('wx/v1/fenghuo/pay/mix', params)
}

// 公众号微信支付--烽火
export function payWxRedirect (orderNum) {
    return api.post('wx/v1/fenghuo/pay/wxRedirect', { order_num: orderNum })
}


//获取打金传说的活动
export function discountActivity(params)
{
    return api.post('wx/v1/gold/discount/activity', params)
}

//获取烽火龙城小游戏订单信息
export function getFhOrderInfo(orderNum)
{
    return api.post('wx/v1/mini/getOrderInfo', { order_num: orderNum })
}
//获取烽火龙城小游戏订单信息
export function checkRole(params)
{
    return api.get('wx/v1/fenghuo/checkRole', { params:params })
}



