import axios from 'axios'
import { Toast } from 'vant'
import { getToken, setToken, removeToken } from './auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_HOST_URL, // 部署地址   
  // baseURL: "https://wx.playhy.com/"
  // baseURL: "http://api.mfms.xhppgames.cc"
})

service.interceptors.request.use(
  config => {
    // config.headers['Accept'] = 'application/json'
    if (getToken()) {
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization)
    }
    if (response.data.code === 401) {
      removeToken()
      // removeUsername()
      // Toast.fail('用户未登录或登录信息已失效')
      Toast.fail('请重新登录')
      return Promise.reject(response.data)
    }
    if (response.data.code !== 0) {
      if(response.data.code != 504){
        Toast.fail(response.data.message)
      }
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data.data)
  },
  error => {
    let message = error.message
    if (error.response.status === 401) {
      removeToken()
      Toast.fail('请重新登录')
      // let second = 2
      // const timer = setInterval(() => {
      //   second--
      //   if (second <= 0) {
      //     clearInterval(timer)
      //     Toast.clear()
      //     location.reload()
      //   }
      // }, 1000)
    }
    switch (error.response.status) {
      case 401: {
        message = '请重新登录'
        removeToken()
        break
      }
      case 422:
        // message = ''
        message = error.response.data.message
        // if (error.response.data && error.response.data.errors) {
        //   for (const key in error.response.data.errors) {
        //     const errors = error.response.data.errors[key]
        //     for (const index in errors) {
        //       message += errors[index] + '<br>'
        //     }
        //   }
        // }
        break
      default:
        message = '请求错误，请联系客服!'
        break
    }
    if(error.response.status != 504){
      Toast.fail(message)
    }
    return Promise.reject(error)
  }
)

export default service
